html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.image-gallery-icon {
  color: #fff;
  appearance: none;
  cursor: pointer;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
  background-color: #0000;
  border: 0;
  outline: none;
  transition: all .3s ease-out;
  position: absolute;
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }

  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}

.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button, .image-gallery-play-button {
  padding: 20px;
  bottom: 0;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}

@media (max-width: 768px) {
  .image-gallery-fullscreen-button, .image-gallery-play-button {
    padding: 15px;
  }

  .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}

@media (max-width: 480px) {
  .image-gallery-fullscreen-button, .image-gallery-play-button {
    padding: 10px;
  }

  .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}

@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}

@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}

.image-gallery-left-nav[disabled], .image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: .6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -webkit-tap-highlight-color: #0000;
  position: relative;
}

.image-gallery.fullscreen-modal {
  height: 100%;
  width: 100%;
  z-index: 5;
  background: #000;
  position: fixed;
  inset: 0;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  line-height: 0;
  position: relative;
  top: 0;
}

.image-gallery-content.fullscreen {
  background: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  width: calc(100% - 110px);
  display: inline-block;
}

@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  white-space: nowrap;
  text-align: center;
  line-height: 0;
  position: relative;
  overflow: hidden;
}

.image-gallery-slide {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.image-gallery-slide.center {
  position: relative;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}

.image-gallery-slide .image-gallery-description {
  color: #fff;
  white-space: normal;
  background: #0006;
  padding: 10px 20px;
  line-height: 1;
  position: absolute;
  bottom: 70px;
  left: 0;
}

@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    padding: 8px 15px;
    font-size: .8em;
    bottom: 45px;
  }
}

.image-gallery-bullets {
  width: 80%;
  z-index: 4;
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.image-gallery-bullets .image-gallery-bullets-container {
  text-align: center;
  margin: 0;
  padding: 0;
}

.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 50%;
  outline: none;
  margin: 0 5px;
  padding: 5px;
  transition: all .2s ease-out;
  display: inline-block;
  box-shadow: 0 2px 2px #1a1a1a;
}

@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}

@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}

.image-gallery-bullets .image-gallery-bullet:focus {
  background: #337ab7;
  border: 1px solid #337ab7;
  transform: scale(1.2);
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
  border: 1px solid #fff;
  transform: scale(1.2);
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }

  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}

.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  vertical-align: top;
  width: 100px;
  display: inline-block;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  margin-right: 0;
  padding: 0;
  display: block;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-top: 2px;
  margin-left: 0;
}

.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  padding: 5px 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  width: 100px;
  background: none;
  border: 4px solid #0000;
  padding: 0;
  transition: border .3s ease-out;
  display: inline-block;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail {
    width: 81px;
    border: 3px solid #0000;
  }
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 4px solid #337ab7;
  outline: none;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    border: 4px solid #337ab7;
    outline: none;
  }
}

@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px 2px #1a1a1a;
  white-space: normal;
  width: 100%;
  padding: 5%;
  font-size: 1em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}

.image-gallery-index {
  color: #fff;
  z-index: 4;
  background: #0006;
  padding: 10px 20px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 768px) {
  .image-gallery-index {
    padding: 5px 10px;
    font-size: .8em;
  }
}

/*# sourceMappingURL=index.c161edd6.css.map */
